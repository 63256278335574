<template>
  <!--  保单售后-->
  <div class="afterSales">
    <crumbs :item="title"></crumbs>
    <div class="banner_box">
      <div class="banner">
        <img :src="banner ? banner : img" alt="" />
      </div>
    </div>
    <div class="tabs_box">
      <div class="tabs">
        <div
          class="tabs_son"
          v-for="item in list"
          :key="item.labelType"
          @click="cut(item.labelType)"
        >
          <div class="title_box" :class="{ blue: item.labelType == ins }">
            <p>{{ item.labelName }}</p>
            <!--            <div>{{item.text}}</div>-->
          </div>
          <img
            :class="{ active: item.labelType == ins }"
            src="@/static/images/secondaryMenu/productsServices/line_check@2x.jpg"
            alt=""
          />
        </div>
        <div class="line_box">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div
      v-for="item in list"
      :key="item.labelType"
      v-show="ins == item.labelType"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div v-if="total == 0" class="content">
        <div v-if="item.labelName == '投诉指引'">
          <template>
            <div class="online">
              <main>
                <div class="formData">
                  <el-form label-position="top" :model="formData" ref="formRef">
                    <el-row>
                      <el-col :span="!isIos ? 8 : 20" :offset="2">
                        <el-form-item label="您想针对哪方面为我们留言：">
                          <el-select
                            v-model="formData.type"
                            style="width: 100%"
                          >
                            <el-option label="投诉" value="0"></el-option>
                            <el-option label="建议" value="1"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <!--          <template v-if="formData.type">-->
                    <!-- <el-row>
                      <el-col :span="16" :offset="4">
                        <div class="formData_title">请您留言</div>
                      </el-col>
                    </el-row> -->
                    <!-- <el-row>
                      <el-col :span="10" :offset="4">
                        <el-form-item label="标题">
                          <el-input
                            v-model="formData.title"
                            placeholder="请输入标题内容"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                    <el-row>
                      <el-col :span="!isIos ? 16 : 20" :offset="2">
                        <div class="formData_title">
                          请留下您的个人信息以及联系方式
                          <span>(电子邮件或者电话至少填写一项)</span>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 8 : 20" :offset="2">
                        <el-form-item label="合同编号">
                          <el-input
                            v-model="formData.userno"
                            placeholder="请输入合同编号"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 8 : 10" :offset="2">
                        <el-form-item label="您的姓名">
                          <el-input
                            v-model="formData.name"
                            placeholder="请输入您的姓名"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="!isIos ? 8 : 10">
                        <el-form-item label="您的称呼">
                          <el-radio-group
                            v-model="formData.sex"
                            class="sex-radio"
                            style="width: 100%"
                          >
                            <el-radio-button label="1">男士</el-radio-button>
                            <el-radio-button label="0">女士</el-radio-button>
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                      <!-- <el-col :span="6"> -->
                      <!-- <el-form-item label="所在省/直辖市">
                          <el-select v-model="formData.province" style="width: 100%">
                            <el-option
                              v-for="(val, key) in province"
                              :key="key"
                              :label="val"
                              :value="val"
                            />
                          </el-select>
                        </el-form-item> -->
                      <!-- <el-form-item label="座机">
                          <el-input
                            placeholder="请输入座机号"
                            v-model="formData.phone"
                          ></el-input>
                        </el-form-item>
                      </el-col> -->
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 8 : 10" :offset="2">
                        <el-form-item label="座机">
                          <el-input
                            placeholder="请输入座机号"
                            v-model="formData.phone"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 8 : 10" :offset="2">
                        <el-form-item label="电子邮件">
                          <el-input
                            v-model="formData.email"
                            placeholder="请输入电子邮件"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="!isIos ? 8 : 10">
                        <el-form-item label="手机">
                          <el-input
                            v-model="formData.mobile"
                            maxlength="11"
                            minlength="11"
                            placeholder="输入手机号"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 16 : 20" :offset="2">
                        <el-form-item label="留言内容">
                          <el-input
                            type="textarea"
                            :rows="7"
                            v-model="formData.content"
                            placeholder="请输入留言内容"
                            style="width: 100%"
                          >
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="40">
                      <el-col :span="!isIos ? 4 : 10" :offset="7">
                        <div class="submit-btn" @click="submit">确认提交</div>
                      </el-col>
                    </el-row>
                    <!--          </template>-->
                  </el-form>
                </div>
              </main>
              <Vcode :show="isShow" @success="onSauccess" @close="onClose" />
            </div>
          </template>
        </div>
        <div class="main" v-else-if="item.labelContent">
          <MobileMainContainer v-if="isMobile && isIos">
            <template #content>
              <div v-html="item.labelContent" />
            </template>
          </MobileMainContainer>
          <main v-else>
            <Editor
              class="editor"
              :readonly="true"
              :content="item.labelContent"
              :height="0"
            ></Editor>
          </main>
        </div>
        <template v-else>
          <div>{{ item }}</div>
        </template>

        <!-- <Editor
          class="editor"
          :readonly="true"
          v-else-if="item.labelContent"
          :content="item.labelContent"
          :height="0"
        ></Editor> -->
      </div>

      <div v-else>
        <!--        <div class="download_text">文件下载</div>-->
        <div class="main_box" v-if="total">
          <div class="box">
            <!--        <download :matters="matters"></download>-->
            <div class="matters">
              <ul>
                <li v-for="(item, index) in matters" :key="index">
                  <a :href="item.fileUrl" target="_blank">
                    <p class="matters_title">
                      {{ item.fileTitle }}
                    </p>
                  </a>
                  <div>
                    <p>{{ item.fileDate }}</p>
                    <p>
                      <span>文件格式:{{ item.fileType }}</span>
                    </p>
                    <p>
                      <span class="matters_time"
                        >文件大小:{{ tansFileSize(item.fileSize) }}</span
                      >
                    </p>
                  </div>
                  <div class="matters_download">
                    <a :href="item.fileUrl" target="_blank">
                      <img
                        src="@/static/images/secondaryMenu/disclosure/xiazai@2x.png"
                        alt=""
                      />
                      <p>下载</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          <div class="paging" v-show="ins=='AZ0180304'|| ins=='AZ0180206' || ins=='AZ0190102'">
            <p>共有{{ total }}条记录</p>
            <div>
              <img
                @click="getPageNumAReduction()"
                src="~@/static/images/l_arrow@2x.png"
                alt=""
              />
              <span>{{ pageNum }}/{{ pages }}</span>
              <img
                @click="getPageNumAdd()"
                src="~@/static/images/ic_arrow_black@2x.png"
                alt=""
              />
            </div>
        </div>
              <!-- <Paging  class="page" :total="total" :pageNum="pageNum" :pages="pages"></Paging> -->
          </div>
        </div>
        <div class="main_box" v-else>
          <empty></empty>
        </div>
        <!--      <download></download>-->
        <!--      <div class="download_line"></div>-->
        <!--      <ul class="link_list">-->
        <!--        <li v-for="item in moreLink" :key="item.title" @click="jumpTo(item.link)">-->
        <!--          <img src="@/static/images/right.png" alt="">-->
        <!--          <p>{{item.title}}</p>-->
        <!--        </li>-->
        <!--      </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
import { azCustomerList } from "@/api/productsServices";
import crumbs from "@/components/crumbs";
import { enumsList } from "@/constant";
import Editor from "@/components/TinyMce";
import common from "@/utils/common";
import MobileMainContainer from "@/components/MobileMainContainer/VipTextIndex.vue";
// import Paging from "@/components/paging.vue";
import empty from "@/components/empty";
// import download from "@/components/download.vue";

// const radio = [
//   "变更投保人/被保险人签名样本",
//   "变更客户基本信息",
//   "变更客户重要信息-年龄性别更正",
//   "变更客户重要信息-职业类别变更",
//   "变更续期保险费缴付方式",
//   "变更保单退费/利益支付方式",
//   "变更保险费缴费频率",
//   "变更健康告知资料",
//   "变更客户-变更受益人",
//   "变更客户-变更投保人",
//   "保单余额退还或转移",
//   "补发保险合同",
// ]
const way = ["保险营销员", "投保的银行网点"];
const moreLink = [
  {
    link: "",
    title: "保全业务所需资料一览表",
  },
  {
    link: "/public/contactNumber",
    title: "安联各分支机构营业场所和联系电话",
  },
];
import $ from "jquery";
import { getFileList } from "@/api/public";
import { onlineMessage } from "@/api/service.js";
import Vcode from "vue-puzzle-vcode";

export default {
  name: "afterSales",
  data() {
    return {
      title: [],
      enumsList,
      moreLink,
      selected: require("@/static/images/secondaryMenu/productsServices/bt_check_h@2x.png"),
      noselected: require("@/static/images/secondaryMenu/productsServices/bt_check_n@2x.png"),
      way,
      matters: [],
      total: 0,
      pageNum: 0,
      pages: 0,
      index: 0,
      index1: 0,
      ins: 0,
      active: 0,
      list: undefined,
      banner: undefined,
      img: require("@/static/images/secondaryMenu/productsServices/aftersales_banner@2x.jpeg"),
      loading: false,
      size: null,
      topTitle: "",
      formData: {
        type: "0",
        // title: "",
        content: "",
        name: "",
        sex: "1",
        // province: "",
        email: "",
        userno: "",
        mobile: "",
        phone: "",
      },
      isShow: false,
      preview: false, //预览
      labelType: null, //tab
      isMobile: false,
      isIos: false,
      isPad: false,
    };
  },
  components: { crumbs, empty, Editor, Vcode, MobileMainContainer },
  mounted() {
    $(".check").eq(this.index).attr("src", this.selected);
    $(".check").eq(this.index).siblings().addClass("col");
    $(".check1").eq(this.index).attr("src", this.selected);
    $(".check1").eq(this.index).siblings().addClass("col");
  },
  created() {
    this.isMobile = common.isMobile();
    this.isIos = window.navigator.userAgent.match(/(iPhone|iPod|ios|iPad)/i);
    if (this.isPad) {
      this.isMobile = false;
    }
    console.log(this.$route.query);
    this.labelType = this.$route.query.labelType;
    // let link = window.location.href;
    // if (link.includes("productService/enquiryService")) {
    //   this.preview = true;
    //   console.log("预览");
    // } else {
    //   console.log("生产");
    // }
    // window.scrollTo({
    //   top: 0,
    //   behavior: "auto",
    // });
    this.requestList();
    // this.request();
  },
  methods: {
    submit() {
      if (!this.formData.name) return this.$message.warning("请填写您的姓名");
      if (!this.formData.mobile && !this.formData.phone) {
        return this.$message.warning("请填写您的联系方式");
      }
      if (this.formData.mobile) {
        const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(this.formData.mobile)) {
          return this.$message.warning("请填写正确的手机号");
        }
      }
      if (this.formData.email) {
        const reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
        if (!reg.test(this.formData.email)) {
          return this.$message.warning("请填写正确的邮箱");
        }
      }
      this.isShow = true;
    },
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.ins,this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.ins,this.pageNum);
      }
    },
    async onSauccess() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await onlineMessage({
          timeStamp,
          sign,
          fktype: this.formData.type,
          username: this.formData.name,
          usersex: this.formData.sex,
          userphone: this.formData.mobile,
          telphone: this.formData.phone,
          useremail: this.formData.email,
          userno: this.formData.userno,
          usercontent: this.formData.content,
          userdate: new Date(),
        });
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("您的反馈提交成功");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.formData = this.$options.data().formData;
        }
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      } finally {
        this.isShow = false;
      }
    },
    onClose() {
      this.isShow = false;
    },
    // 截取
    getStr(string, str) {
      let str_before = string.split(str)[0];
      return str_before;
    },
    getStr1(string, str) {
      let str_before = string.split(str)[1];
      return str_before;
    },
    //文件大小
    tansFileSize(size) {
      var data = "";
      if (size < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      let sizestr = data + "";
      return sizestr;
    },

    async requestList() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await azCustomerList({
          timeStamp,
          sign,
          labelParent: this.$route.params.id,
        });
        console.log("list", res.data);
        if (res.data.code == 200) {
          this.list = res.data.data.customerLabelList;
          this.banner = res.data.data.customerServiceBanner;
          this.ins = this.list[0].labelType;
          // --------预览
          // this.ins = this.labelType || this.list[0].labelType;
          // this.request(this.ins);
          console.log("this.ins", this.ins);
          this.size = res.data.data.customerLabelList.length;
          this.enumsList.forEach(
            (item) =>
              (this.topTitle =
                item[res.data.data.customerLabelList[0].labelParent])
          );
          this.getStr1(this.topTitle, "-")
            ? (this.title = [
                {
                  title: "产品服务",
                  link: "",
                },
                {
                  title: this.getStr(this.topTitle, "-"),
                  link: "",
                },
                // {
                //   title: this.preview
                //     ? `${this.getStr(this.topTitle, "-")}-预览页面`
                //     : this.getStr(this.topTitle, "-"),
                //   link: `/personalCustomer/InsuranceAfterSales/${this.$route.params.id}`,
                // },
                {
                  title: this.getStr1(this.topTitle, "-"),
                  link: `/personalCustomer/InsuranceAfterSales/${this.$route.params.id}`,
                },
              ])
            : (this.title = [
                {
                  title: "产品服务",
                  link: "",
                },
                {
                  title: this.getStr(this.topTitle, "-"),
                  link: `/personalCustomer/InsuranceAfterSales/${this.$route.params.id}`,
                },
              ]);
        }
        // if (
        //     res.data.code == 401 ||
        //     res.data.code == 404 ||
        //     res.data.code == 500
        // ) {
        //   this.$router.replace("/found404");
        // }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    async request(id,num) {
      this.loading = true;

      console.log("id", id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getFileList({
          timeStamp,
          sign,
          pageSize: 50,
          pageNum: num,
          fileCategory: id,
          orderByColumn:'file_index',
          isAsc:'asc'
        });
        console.log("文件", res.data);
        if (res.data.code == 200) {
          console.log(res.data);
          if(id=='AZ0180209'){
            window.open(res.data.rows[0].fileUrl);
          }else{
            this.matters = res.data.rows;
            // this.matters.sort((a, b) => a.fileIndex - b.fileIndex);
            this.pageNum = res.data.pageNum;
            this.pages = res.data.pages;
            this.total = res.data.total;
          }
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
        this.loading = false;
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    cut(index) {
       console.log(index);
    if(index=='AZ0180209'){
      this.request(index,null);
      return;
    }
      this.ins = index;
      this.request(this.ins,null);
    },

    jumpTo(menu) {
      console.log(menu);
      this.$router.push(menu);
    },
  },
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

.afterSales {
  .banner_box {
    width: 100%;

    .banner {
      width: 80vw;
      height: 47rem;
      margin: 5rem auto 2rem;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .tabs_box {
    width: 100%;
    border: 1px solid transparent;
    position: relative;
    height: 15rem;

    .tabs {
      //width: 60%;
      margin: 3rem auto 0;
      display: flex;
      flex-wrap: wrap;
      width: 75vw;
      position: relative;
      //justify-content: space-between;
      //text-align: center;

      > .tabs_son {
        position: relative;
        margin: 0 1.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        > .title_box {
          background-color: #dfeff2;
          padding: 1rem 1.8rem;
          font-size: 2rem;
          margin-bottom: 1rem;
          color: #003781;
          cursor: pointer;
          height: 8rem;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 20rem;
          text-align: center;
        }

        .blue {
          color: #fff;
          background-color: #003781;
        }

        > img {
          width: 10rem;
          height: 3rem;
          //position: absolute;
          //bottom: -3.3px;
          z-index: 2;
          //left: 3.48rem;
          display: none;
          align-self: center;
        }
      }
      > .tabs_son:first-child {
        margin-left: 0;
      }
      .active {
        display: block !important;
      }

      //> li:nth-child(1) {
      //  img {
      //    display: block;
      //  }
      //}

      > li:nth-child(2) {
        img {
          left: 1.87rem;
        }
      }

      > li:nth-child(5) {
        img {
          left: 0.8rem;
        }
      }
    }

    .line_box {
      width: 100%;
      /* width: 73.6%; */
      position: absolute;
      //bottom: 6.64rem;
      /* left: 13.2%; */
      bottom: 0.8rem;

      .line {
        //width: 80%;
        margin: auto;
        //height: 2px;
        height: 0.25rem;
        background-color: #003781;
      }
    }
  }

  main {
    width: 100%;
    margin: 5rem 0;

    .main {
      width: 80vw;
      margin: auto;

      .main_title {
        > p {
          width: 5px;
          height: 80%;
          background-color: #003781;
          margin-right: 10px;
        }

        width: 70%;
        //border: 1px solid;
        margin: auto;
        height: 4rem;
        display: flex;
        font-size: 2rem;
        align-items: center;
        color: #003781;
      }

      .main_content {
        width: 70%;
        margin: auto;

        .checkbox {
          display: flex;
          flex-wrap: wrap;

          > li {
            display: flex;
            align-items: center;
            height: 7rem;
            width: 33%;
            cursor: pointer;

            .check {
              width: 2rem;
              height: 2rem;
              border: 1px solid;
              margin-right: 10px;
              //>img{
              //  width: 100%;
              //  height: 100%;
              //}
            }

            > p {
              font-size: 2rem;
              color: #999;
            }

            .col {
              color: #000;
            }
          }
        }
      }
    }
  }

  .content {
    width: 86%;
    //width: 1034px;
    //height: 50vw;
    margin: auto;
    //border: 1px solid;
    //margin-bottom: 5vw;
  }

  .download_text {
    font-size: 3rem;
    text-align: center;
    margin: 0rem 0 6rem;
    color: #003781;
  }

  ::v-deep .matters {
    > ul {
      //width: 55%;
      margin: 0 auto 4rem;
    }
  }

  .download_line {
    width: 55%;
    margin: 5rem auto;
    border: 1px solid #ccc;
    opacity: 0.6;
  }

  .main_box {
    width: 65%;
    margin: auto;

    .box {
      //margin: 4rem 0;

      > .search {
        display: flex;
        align-items: center;
        justify-content: end;

        /deep/ .el-input__inner {
          border: none !important;
          width: 23rem !important;
          border-bottom: 1px solid !important;
          border-radius: 0 !important;
        }

        > img {
          width: 4rem;
          height: 4rem;
          margin-left: 1.5rem;
        }
      }

      .matters {
        > ul {
          > li {
            padding: 10px 0;
            display: flex;
            font-size: 1.8rem;
            justify-content: space-between;
            align-items: center;
            //margin: 3rem 0;

            > a {
              width: 65%;
              text-decoration: none;

              > .matters_title {
                font-size: 2.3rem;
                color: #003781;
              }
            }

            > .matters_time {
            }

            > .matters_download {
              background-color: #003781;
              color: #fff;
              width: 15rem;
              height: 6rem;
              border-radius: 0.5rem;

              > a {
                color: #fff;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                border-radius: 0.5rem;
                font-size: 1.8rem;
                height: 100%;

                > img {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }
        }
      }

      .page {
        width: 100%;
        padding: 0;
      }
    }
  }

  .link_list {
    width: 55%;
    margin: 10rem auto;

    li {
      display: flex;
      align-items: center;
      color: #003781;
      font-size: 2rem;
      margin: 1rem 0;
      cursor: pointer;

      > img {
        width: 15px;
        height: 12px;
        margin: 0 1rem;
      }
    }
  }

  .online {
    main {
      //width: 80%;
      margin: auto;

      .online-banner {
        width: 100%;
        margin: 5rem 0 0;
      }

      .leaveMessage {
        text-align: center;

        p {
          font-family: Heiti SC;
          color: #000000;

          &:first-child {
            font-size: 3.6rem;
            font-weight: 500;
            margin: 8.5rem 0 4rem 0;
          }

          &:last-child {
            font-size: 1.6rem;
            font-weight: 300;
            margin-bottom: 10rem;
          }
        }
      }

      .formData_title {
        background: #003781;
        font-size: 2rem;
        font-family: Heiti SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 5rem;
        padding-left: 2.5rem;
        margin: 3rem 0 4rem -2rem;

        span {
          font-size: 1.4rem;
        }
      }

      .sex-radio {
        ::v-deep label {
          width: 50%;

          .el-radio-button__inner {
            width: 100%;
          }
        }

        ::v-deep label.is-active .el-radio-button__inner {
          background-color: rgba(0, 55, 129, 1);
        }
      }

      .submit-btn {
        height: 7rem;
        background: #003781;
        font-size: 2.4rem;
        font-family: Heiti SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4rem 0 13rem 0;
        cursor: pointer;
      }
    }
  }
}
::v-deep .paging {
			width: 100%;
			padding: 0;
			margin: 2rem 0;
		}
</style>